import styled from 'styled-components'
import { device, breakpoints } from 'src/styles/breakpoints'
import { white, gray, orange } from 'src/styles/colors'
import BannerHeaderMd from '../assets/images/banner-renda-fixa-desktop.png'

export const Section = styled.section`

  @media (min-width: ${breakpoints.md}) {
    background-image: url(${BannerHeaderMd});
    min-height: 400px;
    background-size: 450px;
    background-repeat: no-repeat;
    background-position-y: calc(100%);
    background-position-x: right;
  }

  @media (min-width: ${breakpoints.lg}) {
    min-height: 490px;
    background-size: 550px;
    padding-top: 32px;
  }

  @media (min-width: ${breakpoints.xl}) {
    min-height: 520px;
    background-size: 600px;
    padding-top: 48px;
    background-position-x: 85%;
  }

  @media (min-width: ${breakpoints.xxxl}) {
    background-position-x: 70%;
  }

  /* .btn {
    height: 40px;
    @media ${device.desktopLG} {
      min-width: 380px;
    }
    @media ${device.desktopXL} {
      min-width: 418px;
    }
  } */

  .gatsby-image-wrapper {
    @media ${device.desktopXL} {
      width: 400px;
    }
  }


  .bread {
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const FAQSection = styled.section`
  .search-input {
    background: ${white};
  }
`

export const Button = styled.button`
  border-radius: 8px;
  background: ${orange.extra};
  color: ${white};
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  border: none;
  width: 100%;

  height: 40px;
    @media ${device.desktopLG} {
      min-width: 380px;
    }
    @media ${device.desktopXL} {
      min-width: 418px;
    }

  &:focus {
    outline: none;
  }

  @media(min-width: ${breakpoints.lg}) {
    max-width: 456px;
  }
`
