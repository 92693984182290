import React from 'react'

import MarkdownIcon from 'src/components/UI/MarkdownIcon'
import Link from 'src/components/GatsbyLinkWrapper/index'

type InvestmentsCardProps = {
  link: string;
  title: string;
  description: string;
  textbutton?: string;
  benefitOne?: string;
  benefitTwo?: string;
  list?: {
    icon: string[];
  };
  id?: string;
  slug?: string;
  onClick?: () => void;
};

export const InvestmentsCard = ({
  link,
  title,
  description,
  textbutton,
  benefitOne,
  benefitTwo,
  list,
  id,
  slug,
  onClick
}: InvestmentsCardProps) => {
  return (
    <div className='col-12 col-md-6 col-lg-3 col-lg-3 mb-4'>
      <Link to={link} title={title} onClick={onClick}>
        <div
          className={`border rounded-3 bg-products px-3 h-100
          ${!!slug && !!id && slug.includes(id) && 'active'}`}
        >
          <h3 className='fs-20 my-4 text-grayscale--500 font-sora'>{title}</h3>
          <p className='fs-14 description-text text-grayscale--400'>{description}</p>

          {benefitOne && benefitTwo && (
            <ul className='list-unstyled d-none d-lg-block icon-list'>
              <li className='d-flex align-items-center mb-3 icon-list__item'>
                <div className='mr-2'>
                  <MarkdownIcon
                    key={list?.icon[0]}
                    width='40'
                    height='40'
                    icon={list?.icon[0]}
                    directory='v2'
                  />
                </div>
                <p className='fs-16 lh-20 fw-400'>{benefitOne}</p>
              </li>
              <li className='d-flex align-items-center icon-list__item'>
                <div className='mr-2'>
                  <MarkdownIcon
                    key={list?.icon[1]}
                    width='40'
                    height='40'
                    icon={list?.icon[1]}
                    directory='v2'
                  />
                </div>
                <p className='fs-16 lh-20 fw-400'>{benefitTwo}</p>
              </li>
            </ul>
          )}

          {textbutton && (
            <div className='text-center pb-3'>
              <a className='btn btn--orange btn-lg rounded-3'>{textbutton}</a>
            </div>
          )}
        </div>
      </Link>
    </div>
  )
}
