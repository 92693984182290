import React, { memo } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import BlogArticlesJSON from './BlogArticles.json'

type BlogArticlesDataItemProps = {
  image: string;
  title: string;
  description: string;
  link: string;
  alt: string;
}

type BlogArticlesDataProps = {
  [param: string]: BlogArticlesDataItemProps[];
}

type BlogArticlesProps = {
  type: string;
  section: string;
  elementAction: 'click button';
  elementName: string;
  sectionName: string;
}

function BlogArticles ({ type, section, elementAction, elementName, sectionName }: BlogArticlesProps) {
  const [ sendDatalayerEvent ] = useDataLayer()
  const blogArticlesData: BlogArticlesDataProps = BlogArticlesJSON
  const data = useStaticQuery(graphql`
    query {
      comoInvestirEmFundos: imageSharp(fluid: {originalName: { regex: "/blog-como-investir-em-fundos/" }}) {
        fluid(maxWidth: 370, quality: 88) {
          ...GatsbyImageSharpFluid
        }
      }
      reformaDaPrevidencia: imageSharp(fluid: {originalName: { regex: "/blog-reforma-da-previdencia/" }}) {
        fluid(maxWidth: 370, quality: 88) {
          ...GatsbyImageSharpFluid
        }
      }
      homeBroker: imageSharp(fluid: {originalName: { regex: "/blog-home-broker/" }}) {
        fluid(maxWidth: 370, quality: 88) {
          ...GatsbyImageSharpFluid
        }
      }
      aberturaContaDigitalPj: imageSharp(fluid: {originalName: { regex: "/blog-abertura-conta-digital-pj/" }}) {
        fluid(maxWidth: 370, quality: 88) {
          ...GatsbyImageSharpFluid
        }
      }
      contaDigitalParaEmpresas: imageSharp(fluid: {originalName: { regex: "/blog-conta-digital-para-empresas/" }}) {
        fluid(maxWidth: 370, quality: 88) {
          ...GatsbyImageSharpFluid
        }
      }
      gestaoFinanceiraEmpresarial: imageSharp(fluid: {originalName: { regex: "/blog-gestao-financeira-empresarial/" }}) {
        fluid(maxWidth: 370, quality: 88) {
          ...GatsbyImageSharpFluid
        }
      }
      educacaoFinanceiraParaCriancasQuandoComecar: imageSharp(fluid: {originalName: { regex: "/financas-para-criancas-como-comecar/" }}) {
        fluid(maxWidth: 370, quality: 88) {
          ...GatsbyImageSharpFluid
        }
      }
      maternidadeComoSePrepararFinanceiramente: imageSharp(fluid: {originalName: { regex: "/maternidade-como-se-preparar-financeiramente/" }}) {
        fluid(maxWidth: 370, quality: 88) {
          ...GatsbyImageSharpFluid
        }
      }
      educacaoFinanceiraParaCriancas: imageSharp(fluid: {originalName: { regex: "/educacao-financeira-para-criancas/" }}) {
        fluid(maxWidth: 370, quality: 88) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return (
    <div className='row py-4'>
      {blogArticlesData[type].map((item: BlogArticlesDataItemProps) => (
        <div key={item.title} className='col-12 col-md-6 col-lg-4 mb-4 mb-lg-0'>
          <article className='col-12 box-shadow px-0 h-100'>
            <Img fluid={data[item.image].fluid} alt={item.alt} />
            <div className='col-12 pt-3 px-kids px-0'>
              <p className='fs-17 fw-600 mb-2 title lh-25 text-grayscale--500 font-sora'>{item.title}</p>
              <p className='fs-14 fs-md-16 mb-2 lh-18 lh-md-20 text-grayscale--400'>{item.description}</p>
              <a
                href={item.link}
                onClick={() => {
                  sendDatalayerEvent({
                    section: section,
                    element_action: elementAction,
                    element_name: elementName + ' - ' + item.title,
                    section_name: sectionName,
                    redirect_url: item.link,
                    c_page: window.location.href,
                  })
                }}
                className='text-uppercase fs-14 fw-500' target='_blank' rel='noreferrer'
              >
                Saiba mais
              </a>
            </div>
          </article>
        </div>
      ))}
    </div>
  )
}

export default memo(BlogArticles)
